.cart {
  position: fixed;
  bottom: 65px;
  right: 15px;
  height: 45px;
  width: 45px;
}
.cart .cart-icon {
  height: 100%;
  width: 100%;
  padding: 20px;
  background: white;
  border: 1px solid black;
}
.cart .cart-icon img {
  transform: translateX(-3px);
}
.items {
  position: fixed;
  max-height: calc(100% - 180px);
  overflow-y: scroll;
  bottom: 110px;
  right: 8px;
  width: 350px;
  padding: 16px;
  background: white;
  border-radius: 4px;
  border: 2px solid black;
}
.items .item {
  display: flex;
  justify-content: flex-start;
  height: 90px;
}
.items .item .img-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 30%;
}
.items .item .img-wrap img {
  height: 100%;
  max-width: 100%;
}
.items .item p {
  margin-left: 16px;
}

/*# sourceMappingURL=cart.css.map */
