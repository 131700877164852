.login-page {
  height: 100%;
  background: rgba(0, 27, 76, 0.3);
}
.login-page .login-wrapper {
  position: absolute;
  right: 50%;
  top: 30%;
  transform: translateY(-50%) translateX(50%);
  width: 250px;
  padding: 30px;
  background: white;
  border-radius: 10px;
}
.login-page .login-wrapper img {
  width: calc(100% + 60px);
  margin-left: -30px;
  margin-right: -30px;
  margin-top: -20px;
  border-radius: 10px;
}
.login-page .login-btn {
  width: 100%;
  height: 30px;
  margin-top: 16px;
  font-weight: 400;
}

/*# sourceMappingURL=login.css.map */
