.options input[type=button] {
  min-width: 150px;
  cursor: pointer;
  margin-right: 8px;
  margin-bottom: 8px;
}
.options input[type=button]:hover, .options .selected {
  border: 1px solid black;
  background: #aac57a;
}

/*# sourceMappingURL=options.css.map */
