.backoffice-products .product-image {
  max-width: 256px;
  padding: 0;
  margin: 10px;
  margin-bottom: 30px;
}
.backoffice-products .product {
  position: relative;
  border: 1px solid #0660ff;
  width: calc(100% - 40px);
  padding: 20px;
}
.backoffice-products .product .product-image {
  max-width: 100px;
}
.backoffice-products .product .icon-wrapper {
  display: none;
}
.backoffice-products .product .product-images {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.backoffice-products .product .product-images .product-image-controls {
  position: relative;
}
.backoffice-products .product .product-images .product-image-controls:hover .icon-wrapper {
  display: block;
}
.backoffice-products .product:hover .disolve-product {
  display: block;
}
.backoffice-products .main-content {
  display: flex;
  align-items: flex-start;
}
.backoffice-products .ungrouped-images {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 95%;
  margin: 0 auto;
}
.backoffice-products .product.selected {
  opacity: 0.5;
}
.backoffice-products .selected-preview {
  position: fixed;
  display: flex;
  max-width: calc(100% - 300px);
  height: 100px;
  bottom: 30px;
  left: 250px;
  overflow: auto;
  flex-wrap: nowrap;
  padding: 8px 8px 8px 0px;
  border: 1px solid lightgrey;
  background: white;
  border-radius: 8px;
}
.backoffice-products .selected-preview .product-image.small {
  max-height: 100%;
  margin: 0 0 0 8px;
}

/*# sourceMappingURL=backoffice-products.css.map */
