.simple-table {
  border-collapse: collapse;
}
.simple-table .clickable {
  cursor: pointer;
}
.simple-table td, .simple-table th {
  text-align: left;
  padding-right: 8px;
}
.simple-table td:not(.delete-btn) {
  padding-right: 16px;
}
.simple-table .clickable:hover {
  text-decoration: underline;
}
.simple-table th.order-by {
  text-decoration: underline;
  border-collapse: collapse;
}
.simple-table th.order-by td, .simple-table th.order-by th {
  text-align: left;
}
.simple-table th.order-by td:not(.delete-btn) {
  padding-right: 16px;
}
.simple-table th.order-by .delete-btn {
  opacity: 0;
}
.simple-table th.order-by tr:hover {
  cursor: default;
}
.simple-table th.order-by tr:hover .delete-btn {
  opacity: 1;
}

.simple-table.show-hover tbody tr:hover {
  background: var(--app-dark-blue);
  color: white;
  cursor: pointer;
}

/*# sourceMappingURL=simple-table.css.map */
