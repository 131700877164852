.image-with-text-section {
  display: flex;
  padding: 12rem 1rem;
  flex-wrap: wrap;
}
.image-with-text-section.horizontal {
  flex-direction: row;
}
.image-with-text-section img {
  flex: 2;
  width: 100%;
  min-width: 300px;
  max-height: 60vh;
  object-fit: cover;
  object-position: center;
  border-radius: 8px;
}
.image-with-text-section .text-side {
  flex: 2;
  padding: 20px;
  font-size: 1.2rem;
  min-width: 300px;
}
.image-with-text-section .text-side h1, .image-with-text-section .text-side h2, .image-with-text-section .text-side p {
  margin: 0;
  padding: 0;
}
.image-with-text-section .text-side p {
  margin-bottom: 1.2rem;
}
.image-with-text-section .text-side p:last-child {
  margin-bottom: 3rem;
}
.image-with-text-section .text-side h2 {
  margin-bottom: 1rem;
}

/*# sourceMappingURL=image-with-text-section.css.map */
