.icon-wrapper {
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 100%;
  padding: 0;
  width: 24px;
  height: 24px;
  background: rgba(148, 0, 32, 0.69);
}
.icon-wrapper img {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

.icon-wrapper:hover {
  cursor: pointer;
  background: lightblue;
}

/*# sourceMappingURL=icon-wrapper.css.map */
