html, body, #root, .App {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  font-family: "Raleway", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body h1, body h2, body h3, body h4, body h5 {
  font-family: "Arima Madurai", "Raleway", "sans-serif";
  font-weight: 200;
}
body input {
  font-family: "Raleway", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

:root {
  --happy-blue: #343eFF;
  --light-grey: #899ea7;
  --lighter-grey: #97adb6;
  --darker-blue: #343e52;
  --dark-blue: #2e4e63;
  --dark-font: black;
  --light-font: white;
}

/*# sourceMappingURL=index.css.map */
