.edit-product {
  height: 100%;
  width: 100%;
  display: flex;
}
.edit-product .image-editor-wrapper {
  flex: 5;
  display: flex;
  position: relative;
}
.edit-product .image-editor-wrapper .selected-image {
  width: 600px;
  overflow: hidden;
}
.edit-product .image-editor-wrapper .selected-image > * {
  max-height: 100%;
  max-width: 100%;
}
.edit-product .image-editor-wrapper .react-cropper {
  position: relative;
  max-height: 100%;
}
.edit-product .image-editor-wrapper .react-cropper img {
  max-width: 100%;
  max-height: 100%;
}
.edit-product .image-editor-wrapper .react-cropper .ReactCrop__crop-selection {
  position: absolute;
  border: 1px solid cyan;
}
.edit-product .image-editor-wrapper .react-cropper img {
  max-width: 100%;
  max-height: 100%;
}
.edit-product .image-editor-wrapper .all-images-wrapper {
  display: flex;
  flex-direction: column;
  max-height: 80%;
  overflow-y: auto;
}
.edit-product .image-editor-wrapper .all-images-wrapper .preview-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.edit-product .image-editor-wrapper .all-images-wrapper .preview-wrapper .small-img-wrapper {
  position: relative;
}
.edit-product .image-editor-wrapper .all-images-wrapper .preview-wrapper .img-preview {
  height: 200px;
}
.edit-product .image-editor-wrapper .all-images-wrapper .preview-wrapper:not(:nth-of-type(1)) {
  margin-left: 8px;
}
.edit-product .image-editor-wrapper .all-images-wrapper .new-img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
}
.edit-product .controls {
  min-width: 250px;
  flex: 1;
  padding: 8px;
  z-index: 10;
  background: white;
}

/*# sourceMappingURL=edit-product.css.map */
