.k-tabs {
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: space-evenly;
}
.k-tabs .tab-container {
  height: 100%;
  margin: 0;
  padding: 0;
  background: var(--darker-blue);
  color: white;
  flex-grow: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}
.k-tabs .tab-container:hover:not(.selected) {
  text-decoration: underline;
}
.k-tabs .selected {
  background: var(--dark-blue);
}

/*# sourceMappingURL=k-tabs.css.map */
