.backoffice-page {
  display: flex;
  height: 100%;
}
.backoffice-page .menu {
  position: fixed;
  height: 100%;
  width: 225px;
  flex: 2;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  border-right: 1px solid lightgrey;
  text-align: center;
}
.backoffice-page .menu .link-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.backoffice-page .menu a, .backoffice-page .menu h2, .backoffice-page .menu h3, .backoffice-page .menu h4, .backoffice-page .menu h5 {
  padding-left: 32px;
  width: calc(100% - 32px);
  text-align: left;
}
.backoffice-page .menu a {
  text-decoration: none;
  color: black;
  padding-top: 10px;
  padding-bottom: 10px;
}
.backoffice-page .menu a:hover {
  background: darkblue;
  color: white;
}
.backoffice-page .menu a.curr {
  text-decoration: underline;
}
.backoffice-page .menu hr {
  width: 100%;
}
.backoffice-page .content {
  flex: 10;
  padding-left: 16px;
  margin-left: 225px;
}

/*# sourceMappingURL=backoffice-page.css.map */
