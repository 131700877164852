.grid-gallery {
  width: 100%;
  margin-top: 45px;
  margin-bottom: 45px;
}
.grid-gallery .popup-img {
  max-width: 100%;
  margin-bottom: -4px;
}
.grid-gallery .grid {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}
.grid-gallery .grid .img-wrapper {
  width: 250px;
  height: 250px;
  overflow: hidden;
  display: flex;
  align-items: center;
  margin: 5px;
}
.grid-gallery .grid .img-wrapper img {
  width: 100%;
}

/*# sourceMappingURL=grid-simple-image-collection.csse-collection.css.map */
