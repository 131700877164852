.wedding-page {
  padding: 8px;
  width: calc(70%);
  min-width: 364px;
  max-width: 820px;
  margin: 0 auto;
  border: 5px solid #cbd9c9;
  background: white;
  color: black;
}
.wedding-page .logo {
  width: 40%;
  min-width: 350px;
}
.wedding-page .pynt-top {
  transform: rotate(180deg) translateY(9px);
  margin-bottom: -22px;
  width: 100%;
}
.wedding-page .pynt-bot {
  width: 100%;
}
.wedding-page .text-area {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border: 2px solid #cbd9c9;
}
.wedding-page .horizontal {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.wedding-page section {
  padding-top: 30px;
  padding-bottom: 30px;
}
.wedding-page section img {
  max-width: 50%;
}
.wedding-page section .icon {
  margin: 0 auto;
}
.wedding-page section .icon img {
  height: 24px;
  width: 24px;
  padding: 8px;
  border-radius: 100%;
  background: #084336;
  margin: 0;
  margin-bottom: 2px;
}
.wedding-page section .icon p {
  margin: 0;
  margin-bottom: 22px;
}
.wedding-page section .icon-wrapper:hover {
  cursor: pointer;
  background: lightblue;
}
.wedding-page section {
  width: 90%;
  max-width: 1400px;
  border-bottom: 1px solid #004b0a;
  border-top: 1px solid #004b0a;
  border-radius: 30px;
}
.wedding-page section .title-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.wedding-page section .title-wrapper img {
  margin: 0;
  padding: 0;
  height: 145px;
  transform: scale(2);
}
.wedding-page section .title-wrapper .twig_left {
  transform: scale(2) translateX(-15px) rotate(80deg);
}
.wedding-page section h1 {
  font-size: 42px;
  margin: 0;
}
.wedding-page section h2 {
  margin: 0;
  margin-top: 8px;
  width: 100%;
  font-size: 36px;
}
.wedding-page section .address {
  font-size: 26px;
}
.wedding-page section .date, .wedding-page section .time-until {
  font-size: 20px;
}
.wedding-page section p {
  font-size: 22px;
  line-height: 28px;
}
.wedding-page section ol {
  text-align: left;
  font-size: 20px;
}
.wedding-page section .camping-quote {
  line-height: 28px;
  padding-top: 16px;
  padding-bottom: 16px;
  margin-top: 32px;
  margin-bottom: 32px;
}
.wedding-page section h3.camping-quote {
  font-size: 26px;
}
.wedding-page section h4.camping-quote {
  font-size: 20px;
}
.wedding-page .dresscode img {
  margin-bottom: 45px;
}
.wedding-page .dresscode h2 {
  font-size: 1.5em;
  margin-bottom: 0;
}
.wedding-page .dresscode p {
  margin-top: 0;
}
.wedding-page .rsvp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 48px;
}
.wedding-page .rsvp .rsvp-img-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
  max-width: 400px;
  margin: 0 auto;
  margin-bottom: 40px;
  border-bottom: 0.5px solid lightgray;
}
.wedding-page .rsvp .rsvp-img-wrap img:first-of-type {
  transform: rotateZ(180deg) rotateX(180deg);
}
.wedding-page .rsvp .input-field:first-of-type {
  margin-top: 42px;
}
.wedding-page .rsvp .input-field {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.wedding-page .rsvp .input-field label {
  width: 100%;
  margin-bottom: 4px;
  font-size: 24px;
}
.wedding-page .rsvp .input-field h5 {
  margin: 0;
  font-size: 1.2em;
}
.wedding-page .rsvp .input-field input {
  min-height: 45px;
  font-size: 1em;
  font-weight: bold;
  border: none;
  border-radius: 4px;
}
.wedding-page .rsvp .input-field input[type=text] {
  border: 1px solid black;
}
.wedding-page .rsvp .input-field hr {
  margin: 41px 0;
  width: 100%;
}
.wedding-page .rsvp .submit-btn {
  width: 100%;
  border-radius: 4px;
  height: 45px;
  margin-top: 32px;
  font-size: 22px;
  text-transform: uppercase;
  cursor: pointer;
}
.wedding-page .toastmaster-section {
  position: relative;
}
.wedding-page .toastmaster-section .title-img {
  position: absolute;
  bottom: 0;
  right: 5px;
  height: 25vmin;
  max-height: 20vmin;
  width: auto !important;
}
.wedding-page .toastmaster-section .left {
  left: 5px;
  transform: rotateY(180deg);
}
.wedding-page .coming-friday div img:last-of-type {
  transform: rotateY(180deg);
}
.wedding-page .coming-friday h3 {
  margin-bottom: 0;
}
.wedding-page .coming-friday p:last-of-type {
  margin-top: 0;
}
.wedding-page .time-section .timeline b, .wedding-page .time-section .timeline p {
  margin: 0;
}
.wedding-page .time-section .timeline p:last-of-type {
  margin-bottom: 32px;
}
.wedding-page .time-section .timeline span {
  display: block;
  font-size: 2em;
  margin: 6px;
  font-weight: lighter;
}
.wedding-page .time-section .friday {
  margin-bottom: 32px;
}
.wedding-page .section-with-image .title-img {
  width: 40%;
  max-width: 250px;
  padding: 0;
  margin: 0;
}
.wedding-page .section-with-image .tidslinje-img {
  margin: -10%;
  margin-bottom: -16%;
}
.wedding-page .section-with-image .coming-friday-img {
  transform: rotateZ(180deg) translateY(60px);
}
.wedding-page .section-with-image h1 {
  margin-top: -15px;
}
.wedding-page section:first-of-type {
  padding-top: 0;
  border-top: none;
}
.wedding-page hr {
  margin-top: 8vmin;
  margin-bottom: 8vmin;
}
.wedding-page .has-rsvp {
  position: fixed;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  height: 45px;
  width: 90%;
  max-width: 700px;
  border: 1px solid black;
  background: #fff9e7;
  border-radius: 3px;
}
.wedding-page .has-rsvp p {
  text-align: center;
}

/*# sourceMappingURL=wedding.css.map */
