.k-button {
  color: white;
  background: #899ea7;
  width: 220px;
  font-weight: 400;
  font-family: "Raleway", sans-serif;
  border: 0;
  border-radius: 2px;
}

.k-button:hover {
  background: #97adb6;
  cursor: pointer;
}

.k-button.big {
  width: 100%;
  height: 45px;
  font-size: 1.3em;
}

/*# sourceMappingURL=k-button.css.map */
