.App .easter-splash {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background: rgba(0, 179, 158, 0.2);
  padding-top: 26px;
  padding-bottom: 26px;
  margin-bottom: 36px;
  font-size: 1.4em;
  text-align: center;
}
.App .easter-splash img {
  width: calc(100% - 20px);
  max-width: 450px;
  margin-left: 42px;
  margin-right: 42px;
}
.App .easter-splash h2 {
  margin-bottom: 0;
}
.App .easter-splash p {
  margin: 0;
  padding: 0;
  padding-left: 8px;
  padding-right: 8px;
  max-width: 700px;
  text-align: center;
}
.App .wide-splash {
  width: 100%;
  max-height: 60vh;
  object-fit: cover;
}
.App section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 40px auto;
  padding-left: 8px;
  padding-right: 8px;
  max-width: 600px;
}
.App section > * {
  padding: 0;
  margin: 0;
}
.App section h1, .App section h2, .App section h3, .App section h4, .App section h5 {
  font-size: 2.4em;
  margin-bottom: 10px;
  color: rgba(58, 113, 27, 0.9);
}
.App section h5 {
  color: black;
}
.App section p, .App section a {
  font-size: 1.5em;
  font-weight: 200;
  padding: 0 4px;
  max-width: 100%;
}
.App section p:not(:last-child) {
  margin-bottom: 1rem;
}
.App section p:last-child {
  margin-bottom: 45px;
}
.App section img {
  max-width: 100%;
}
.App section a {
  margin-top: 30px;
}
.App .yellow-area {
  background: #e2b100;
  background: #d0a300;
  color: white;
  font-weight: 500;
}
.App .yellow-area h2 {
  border-bottom: 4px solid #e7b70e;
  line-height: 2rem;
}

/*# sourceMappingURL=landing-page.css.map */
