.k-input {
  position: relative;
  display: flex;
  flex-direction: column;
}
.k-input .input-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.k-input .non-selectable {
  cursor: pointer;
}
.k-input label {
  margin: 0;
  padding: 0 0 7px;
  font-size: 14px;
  font-weight: 600;
}
.k-input input {
  margin-top: 5px;
  margin-bottom: 15px;
  padding: 5px 12px;
  border: 0.1px solid lightgrey;
  border-radius: 3px;
}
.k-input .error {
  color: red;
}
.k-input .option-wrapper {
  width: calc(100% - 2px);
  border: 1px solid black;
  position: absolute;
  top: 52px;
  background: white;
  z-index: 99;
  max-height: 250px;
  overflow-y: auto;
}
.k-input .drop-option {
  list-style: none;
  font-weight: 400;
  color: black;
  cursor: pointer;
}
.k-input .drop-option:hover {
  border-bottom: 1px solid black;
  font-weight: 700;
}
.k-input .selected-wrapper {
  margin-top: 4px;
  display: flex;
  flex-wrap: wrap;
}
.k-input .selected-wrapper .selected-item {
  border: 1px solid black;
  border-radius: 15px;
  padding: 5px 15px;
  display: flex;
  align-content: center;
  margin-right: 7px;
  margin-bottom: 5px;
  cursor: pointer;
}

/*# sourceMappingURL=k-input.css.map */
