.product-management {
  width: 100%;
}
.product-management .product-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.product-management .product-wrapper .product {
  display: flex;
  height: 150px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 16px;
  border-bottom: 1px solid lightgrey;
}
.product-management .product-wrapper .product .img-wrapper {
  display: flex;
  justify-content: center;
  width: 200px;
  background: whitesmoke;
}
.product-management .product-wrapper .product .img-wrapper img {
  height: 100%;
}
.product-management .product-wrapper .product .price-wrapper {
  margin-left: 16px;
}
.product-management .product-wrapper .product .grow {
  flex-grow: 1;
}
.product-management .product-wrapper .product .button-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 150px;
}
.product-management .product-wrapper .product .button-wrapper input {
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
  display: none;
}
.product-management .product-wrapper .product .button-wrapper .save-btn {
  background: green;
}
.product-management .product-wrapper .product .button-wrapper .delete-btn {
  background: red;
}
.product-management .product-wrapper .product:hover input {
  display: block;
}

/*# sourceMappingURL=inventory-page.css.map */
