.backoffice-main-page {
  width: 100%;
  padding-bottom: 12rem;
}
.backoffice-main-page .existing-images {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
.backoffice-main-page .existing-images img {
  width: 200px;
  object-fit: contain;
}
.backoffice-main-page .selected-popup .primary {
  max-width: 300px;
  object-fit: contain;
}
.backoffice-main-page .selected-popup .copies {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.backoffice-main-page .selected-popup .copies img {
  object-fit: contain;
  max-width: 100%;
  margin-bottom: 2rem;
}

/*# sourceMappingURL=backoffice-main.css.map */
